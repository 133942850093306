// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [ "links", "template" ]
  initialize(){
    $(document).ready(function() {
      $('.total-review').each(function(){
        //scroll height가 client height의 길이를 뛰어넘는 지점에서
        // if (elem.clientHeight < elem.scrollHeight)
        if(this.clientHeight < this.scrollHeight){
          $(this).height(this.scrollHeight)
        }
      })
      $('.each-review').each(function(){
        //scroll height가 client height의 길이를 뛰어넘는 지점에서
        // if (elem.clientHeight < elem.scrollHeight)
        if(this.clientHeight < this.scrollHeight){
          $(this).height(this.scrollHeight)
        }
      })
    })
  }
  connect() { 
    $(window).on('resize', function(){
      $('.total-review').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
      $('.each-review').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
    });
    $('.button_kind_video').on('click',function(event) {

      var myTarget = $(event.target).find('.kind_video')
      if (myTarget.is(":visible")==true){
        myTarget.fadeOut()
        myTarget.hide() 
      }
      else{
        myTarget.fadeIn()
        myTarget.show()
      }
      
    })
  }

  add_player(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_PLAYER/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove_player(event) {
    const wrapper = event.target.closest(".player-review")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    }
    else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }

  getGames(event){
    var date = $('#game_date').val()
    this.fetchData(date)
  }

  fetchData(date) {
    const queryParams = [
      `game_date=${date}`,
      `game_year=${date.split('-')[0]}`
    ];

    fetch(`/games?${queryParams.join("&")}`)
      .then(resp => resp.json())
      .then(data => {
        this.chartData = data;
        this.setSelectBox();
      });
  }
  setSelectBox(){
    $('#game_review_select_games').empty()
    for (let item of this.chartData['games']) {
        let option = `<option value=${item['game_id']}>(${item['game_type']}) ${item['away_team']} vs ${item['home_team']} - ${item['game_number']}차전</option>`;
        $('#game_review_select_games').append(option)
      }
  }

  show_batter_review_sector(event){
    var gameId = document.getElementById('gameId').value;
    var button_batter_review = document.getElementById('button_sector_batter_review');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_review');

    if ($('#button_sector_batter_review').hasClass('bg-black text-white')==false){
      button_batter_review.classList.replace('bg-white', 'bg-black');
      button_batter_review.classList.replace('text-black', 'text-white');
    }
    button_pitcher_review.classList.replace('bg-black', 'bg-white');
    button_pitcher_review.classList.replace('text-white', 'text-black');

    var sector_batter_review = document.getElementById('sector_batter_review');
    sector_batter_review.style.display = 'block';
    var sector_pitcher_review = document.getElementById('sector_pitcher_review');
    sector_pitcher_review.style.display = 'none';
    
    let data = { 
      "kind" : 'show',
      "sector": 'batter_game_review',
      "game_id":gameId,
    }
    setLogs(data)

  }

  show_pitcher_review_sector(event){
    var gameId = document.getElementById('gameId').value;
    var button_batter_review = document.getElementById('button_sector_batter_review');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_review');
    if ($('#button_sector_pitcher_review').hasClass('bg-black text-white')==false){
      button_pitcher_review.classList.replace('bg-white', 'bg-black');
      button_pitcher_review.classList.replace('text-black', 'text-white');
    }
    button_batter_review.classList.replace('bg-black', 'bg-white');
    button_batter_review.classList.replace('text-white', 'text-black');
    
    var sector_batter_review = document.getElementById('sector_batter_review');
    sector_batter_review.style.display = 'none';
    var sector_pitcher_review = document.getElementById('sector_pitcher_review');
    sector_pitcher_review.style.display = 'block';
    
    let data = { 
      "kind" : 'show',
      "sector": 'pitcher_game_review',
      "game_id":gameId,
    }
    setLogs(data)

  }

  show_game_record_sector(event){
    var gameId = document.getElementById('gameId').value;
    var button_batter_review = document.getElementById('button_sector_game_record');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_speed');

    if ($('#button_sector_game_record').hasClass('bg-black text-white')==false){
      button_batter_review.classList.replace('bg-white', 'bg-black');
      button_batter_review.classList.replace('text-black', 'text-white');
    }
    button_pitcher_review.classList.replace('bg-black', 'bg-white');
    button_pitcher_review.classList.replace('text-white', 'text-black');

    var sector_batter_review = document.getElementById('sector_game_record');
    sector_batter_review.style.display = 'block';
    var sector_pitcher_review = document.getElementById('sector_pitcher_speed');
    sector_pitcher_review.style.display = 'none';

    let data = { 
      "kind" : 'show',
      "sector": 'game_record',
      "game_id":gameId,
    }
    setLogs(data)

  }

  show_pitcher_speed_sector(event){
    var gameId = document.getElementById('gameId').value;
    var button_batter_review = document.getElementById('button_sector_game_record');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_speed');
    if ($('#button_sector_pitcher_speed').hasClass('bg-black text-white')==false){
      button_pitcher_review.classList.replace('bg-white', 'bg-black');
      button_pitcher_review.classList.replace('text-black', 'text-white');
    }
    button_batter_review.classList.replace('bg-black', 'bg-white');
    button_batter_review.classList.replace('text-white', 'text-black');
    
    var sector_batter_review = document.getElementById('sector_game_record');
    sector_batter_review.style.display = 'none';
    var sector_pitcher_review = document.getElementById('sector_pitcher_speed');
    sector_pitcher_review.style.display = 'block';

    let data = { 
      "kind" : 'show',
      "sector": 'pitcher_speed',
      "game_id":gameId,
    }
    setLogs(data)

  }

}

function setLogs(data){
  $.ajax({
      url: '/game_review/setLogs',
      method: 'GET',
      async: false,
      data: data
  });        
}


jQuery(document).on(function() {
  let playerListForm = []
  let tick = false
  var ajax_team_player = new XMLHttpRequest();
  ajax_team_player.open("GET", `/lotte_players?`, true)
  ajax_team_player.onload = data => getPlayerListForm(data, ajax_team_player.responseText)
  ajax_team_player.send()

  $(document).on('click','input[id$="player_name"]',function(){
  	if ($(this).parent().attr('class') == 'awesomplete'){}
  	else{
        setDropdownForm($(this).attr('id'))
  	};
  })

  $(document).on('focusout','input[id$="player_name"]',function(){
  	let current_input_id = $(this).attr('id').replace('player_name','')
  	var ajax_player = new XMLHttpRequest();
  	ajax_player.open("GET",`/lotte_players?id=${$(this).val()}`,true);
  	ajax_player.onload = function(data){
  		var player = JSON.parse(ajax_player.responseText)
  		$(`#${current_input_id + 'player_name'}`).val(player['player']['name_kor'])
  		$(`#${current_input_id + 'player_id'}`).val(player['player']['id'])
  	};
  	ajax_player.send();
  })

  function getPlayerListForm(data, response){
    var temp = JSON.parse(response);
    if (!temp.players){
      return;
    }
    temp.players.forEach(p=>{
    	if(p.name_kor != null){
        playerListForm.push([p.name_kor + ' (' + p.back_number + ')',p.player_id]);}
    });
  }

  function setDropdownForm(tag){
  	const input_tag = document.querySelector(`#${tag}`);
  	tick = tag;
    new Awesomplete(input_tag, {
      list: playerListForm,
      minChars: 0
    }); 
  }

})

