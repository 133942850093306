import { Controller } from "stimulus"

export default class extends Controller {

    initialize(){
        $('#coachScoreLeaderboard').tablesorter({
            headers: {0: {sorter: false}}
        })
    }
    createPDF(){
        var year = $('#year_search').val()
        fetch(`/month_evaluations/get_evaluation_scores?year=${year}`)
        .then(resp => resp.json())
        .then(data => {
            this.evaluationScores = data;
            this.setPDFContents();
        });
    }
    setPDFContents(){
        var year = $('#year_search').val()
        let innerHTML = `
                <head>
                    <script src="https://cdn.tailwindcss.com"></script>
                </head>
                <body>
                    <div class="h-[210mm] w-[290mm] px-12 py-8">
                        <h1 id="title">${year}시즌 코칭스태프 평가점수 순위</h1>
                        <table id='leaderboards' class="min-w-full">
                            <thead>
                                <tr>
                                    <th class='sticky left-0 border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">코치명</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">레벨</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">보직</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">그라운드(ON)</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">그라운드(OFF)</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">총점</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </body>
            `

        var el = document.createElement( 'html' );
        el.innerHTML = `<html>${innerHTML}</html>`;
        let leaderboardTbodyElement = el.querySelector('#leaderboards > tbody')
        for (let coachScore of this.evaluationScores['coach_evaluation_scores']) {
            var strHTML = `
                        <tr>
                            <td class='sticky left-0 border whitespace-no-wrap px-2 py-2 bg-white text-center'>${coachScore['name_kor']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['department_name']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['role_name']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['ongroundScore']==null ? '--' : coachScore['ongroundScore']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['offgroundScore']==null ? '--' : coachScore['offgroundScore']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['totalScore']==null ? '--' : coachScore['totalScore']}</td>
                        </tr>
                    `
            leaderboardTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
        };
        var element = el.getElementsByTagName('body')[0]
        html2pdf().from(element).toPdf().save('Scores.pdf')
    }

}