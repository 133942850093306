import { Controller } from "stimulus"

export default class extends Controller {

    initialize(){
        $('#trainerScoreLeaderboard').tablesorter({
            headers: {0: {sorter: false}}
        })
    }
    createPDF(){
        var year = $('#year_search').val()
        fetch(`/evaluations/trainer_evaluation_score_leaderboards/get_evaluation_scores?season=${year}`)
        .then(resp => resp.json())
        .then(data => {
            this.evaluationScores = data;
            this.setPDFContents();
        });
    }
    setPDFContents(){
        var year = $('#year_search').val()
        let innerHTML = `
                <head>
                    <script src="https://cdn.tailwindcss.com"></script>
                </head>
                <body>
                    <div class="h-[210mm] w-[290mm] px-12 py-8">
                        <h1 id="title">${year}시즌 트레이너 평가점수 순위</h1>
                        <table id='leaderboards' class="min-w-full">
                            <thead>
                                <tr>
                                    <th class='sticky left-0 border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">이름</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">편성</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">전문성</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">코칭</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">소통</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">책임감</th>
                                    <th class='border whitespace-no-wrap px-2 py-2' style="background-color: #D6DCE4">총점</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </body>
            `

        var el = document.createElement( 'html' );
        el.innerHTML = `<html>${innerHTML}</html>`;
        let leaderboardTbodyElement = el.querySelector('#leaderboards > tbody')
        for (let coachScore of this.evaluationScores['coach_evaluation_scores']) {
            var strHTML = `
                        <tr>
                            <td class='sticky left-0 border whitespace-no-wrap px-2 py-2 bg-white text-center'>${coachScore['name_kor']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['department_name']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['knowledge']==null ? '--' : coachScore['knowledge']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['coaching']==null ? '--' : coachScore['coaching']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['communication']==null ? '--' : coachScore['communication']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['responsibility']==null ? '--' : coachScore['responsibility']}</td>
                            <td class='border whitespace-no-wrap text-center px-2 py-2'>${coachScore['total']==null ? '--' : coachScore['total']}</td>
                        </tr>
                    `
            leaderboardTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
        };
        var element = el.getElementsByTagName('body')[0]
        html2pdf().from(element).toPdf().save('Scores.pdf')
    }

}