// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
    coachId = document.getElementById('coachId').value;
    initialize(){
        $('.comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
                $(this).height(this.scrollHeight)
            }
        });
    }

    connect() {
        $(window).on('resize', function(){
        $('.comment').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })
        });
    }

    createPDF(){
        var year = $('#season').val()
        fetch(`/evaluations/get_season_evaluations?season=${year}&coachId=${this.coachId}`)
        .then(resp => resp.json())
        .then(data => {
            this.evaluation = data['trainer_season_evaluations'];
            this.setEvaluationPDFContents();
        });
    }

    setEvaluationPDFContents(){
        var trainerInformation = this.evaluation['trainer_information']
        var trainerScore = this.evaluation['trainer_evaluation_score']
        let trainerEvaluations = this.evaluation['user_trainer_evaluations']
        var evaluationHTML = `
                                <head>
                                    <script src="https://cdn.tailwindcss.com"></script>
                                </head>
                                <body>
                                    <div class="h-[210mm] w-[290mm] px-12" id="evaluation">
                                        <div class='inline-flex items-center justify-center w-full'>
                                            <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded">
                                            <h2 class="w-auto px-2 absolute bg-white text-2xl">
                                                ${trainerInformation['season']}시즌 트레이너 평가서
                                            </h2>
                                        </div>

                                        <div>
                                            <h3 class="text-xl font-bold my-1 batting-evaluation">1.트레이너 정보</h3>
                                        </div>

                                        <div class='grid grid-cols-3'>
                                            <div class='col-span-1 text-sm text-center border-r border-t border-l font-bold p-1'>이름</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>편성</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>보직</div>
                                            <div class='col-span-1 text-center border-r border-t border-b border-l text-xs'>
                                                ${trainerInformation['name_kor']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${trainerInformation['department_name']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${trainerInformation['role_name']}
                                            </div>
                                        </div>

                                        <div class='h-2'></div>

                                        <div>
                                            <h3 class="text-xl font-bold my-1 batting-evaluation">2. 평가점수</h3>
                                        </div>

                                        <div class='grid grid-cols-5'>
                                            <div class='col-span-1 text-sm text-center border-r border-t border-l font-bold p-1'>전문성</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>코칭태도</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>소통</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>책임감</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>총점</div>
                                            <div class='col-span-1 text-center border-r border-t border-b border-l text-xs'>
                                                ${trainerScore['knowledge']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${trainerScore['coaching']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${trainerScore['communication']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${trainerScore['responsibility']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${trainerScore['total']}
                                            </div>
                                        </div>

                                        <div class='h-2'></div>

                                        <div>
                                            <h3 class="text-xl font-bold my-1 batting-evaluation">3. 평가내용</h3>
                                        </div>

                                        <div id='trainerEvaluations' class="w-full grid grid-cols-10"></div>
                                    </div>
                                </body>
                            `
        var el = document.createElement( 'html' );
        el.innerHTML = `<html>${evaluationHTML}</html>`;
        let evaluationBody = el.querySelector('#trainerEvaluations')

        for (let i=0;i<trainerEvaluations.length;i++) {
            var evaluation = trainerEvaluations[i]
            if (evaluation['comment']!='<p></p>'){
                var strHTML = `
                            <div class="col-span-2 p-1 flex h-full border-t border-l border-b border-gray-500 items-center justify-center">
                                <div>
                                    <span class="text-sm text-left">작성자 ${i+1}</span>
                                </div>
                            </div>
                            <div class="col-span-8 border p-1 border-gray-500 flex items-center text-xs">
                                ${evaluation['comment']}
                            </div>

                            <div class='h-1'></div>

                `;
                evaluationBody.insertAdjacentHTML( 'beforeend', strHTML );
            }
        }

        var evaluationElement = el.getElementsByTagName('body')[0]
        html2pdf().from(evaluationElement).toPdf().save('evaluation.pdf')

    }

}