import { Controller } from "stimulus"

export default class extends Controller {
    initialize(){
      $('.player-select').select2({
          width: '100%',
          ajax: {
            url: '/scouts/international_scout_pitcher_evaluations/get_international_players',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              var query = {
                  search: params.term
              }
              return query;
            },
            processResults: function (data) {
              return {
                results: data.players,
              };
            },
            cache: true
          },
      });
      $('.position-select').select2({
        width: '100%',
      });
      $('.evaluation-comment').each(function(){
        //scroll height가 client height의 길이를 뛰어넘는 지점에서
        // if (elem.clientHeight < elem.scrollHeight)
        if(this.clientHeight < this.scrollHeight){
          $(this).height(this.scrollHeight)
        }
      })
    }
    connect() { 
      $(window).on('resize', function(){
        $('.evaluation-comment').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
      });
      $('#international_scout_pitcher_evaluation_form').on('submit',function(){
        var is_return = true;

        var player_id = $('#player_id').val()
        var team_id = $('#team_id').val()
        var last_seen_date = $('#last_seen_date').val()
        var position = $('#position').val()
        // var scoutingScore = $('#last_seen_date').val()
        // var currentAvailability = $('#current_availability').val()

        if (player_id==null || player_id==''){
          alert('Intsert Player')
          is_return = false
        } else if (team_id==null || team_id==''){
          alert('Intsert Team')
          is_return = false
        } else if (position==null || position==''){
          alert('Intsert Position')
          is_return = false
        // } else if (scoutingScore==null || scoutingScore==''){
        //   alert('Intsert Scouting Score')
        //   is_return = false
        // } else if (currentAvailability==null || currentAvailability==''){
        //   alert('Intsert Current Availability')
        //   is_return = false
        } else if (last_seen_date==null || last_seen_date==''){
          alert('Intsert last_seen_date')
          is_return = false
        }
        return is_return
      });
    }

    setHeight(event){
      const wrapper = event.target
      wrapper.style.height = 'auto'
      wrapper.style.height=wrapper.scrollHeight+"px"
    }      

    displayToggles(){
        $('#international-scout-evaluations-format').slideToggle(100)
    }
}