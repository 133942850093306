import { Controller } from "stimulus"

export default class extends Controller {
    coachId = document.getElementById('coachId').value;

    button_sector_first = document.getElementById('button_sector_first');
    button_sector_second = document.getElementById('button_sector_second');
    sector_first = document.getElementById('sector_first');
    sector_second = document.getElementById('sector_second');

    initialize(){
        $('.coach-evaluation-comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })        
    }

    connect(){
        $(window).on('resize', function(){
            $('.coach-evaluation-comment').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
        });
    }

    createPDF(){
        var year = $('#year').val()
        fetch(`/month_evaluations/get_season_evaluations?year=${year}&coachId=${this.coachId}`)
        .then(resp => resp.json())
        .then(data => {
            this.evaluation = data['coach_season_evaluations'];
            this.setEvaluationPDFContents();
        });
    }
    setEvaluationPDFContents(){
        var coachInformation = this.evaluation['coach_information']
        var coachScore = this.evaluation['coach_evaluation_score']
        let coachEvaluations = this.evaluation['user_coach_evaluations']
        var evaluationHTML = `
                                <head>
                                    <script src="https://cdn.tailwindcss.com"></script>
                                </head>
                                <body>
                                    <div class="h-[210mm] w-[290mm] px-12" id="evaluation">
                                        <div class='inline-flex items-center justify-center w-full'>
                                            <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded">
                                            <h2 class="w-auto px-2 absolute bg-white text-2xl">
                                                ${coachInformation['year']}시즌 코치 평가서
                                            </h2>
                                        </div>

                                        <div>
                                            <h3 class="text-xl font-bold my-1 batting-evaluation">1. 코치정보</h3>
                                        </div>

                                        <div class='grid grid-cols-3'>
                                            <div class='col-span-1 text-sm text-center border-r border-t border-l font-bold p-1'>이름</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>편성</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>보직</div>
                                            <div class='col-span-1 text-center border-r border-t border-b border-l text-xs'>
                                                ${coachInformation['name_kor']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${coachInformation['department_name']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${coachInformation['role_name']}
                                            </div>
                                        </div>

                                        <div class='h-2'></div>

                                        <div>
                                            <h3 class="text-xl font-bold my-1 batting-evaluation">2. 평가점수</h3>
                                        </div>

                                        <div class='grid grid-cols-3'>
                                            <div class='col-span-1 text-sm text-center border-r border-t border-l font-bold p-1'>그라운드(ON)</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>그라운드(OFF)</div>
                                            <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>총점</div>
                                            <div class='col-span-1 text-center border-r border-t border-b border-l text-xs'>
                                                ${coachScore['ongroundScore']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${coachScore['offgroundScore']}
                                            </div>
                                            <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                                ${coachScore['totalScore']}
                                            </div>
                                        </div>

                                        <div class='h-2'></div>

                                        <div>
                                            <h3 class="text-xl font-bold my-1 batting-evaluation">3. 평가내용</h3>
                                        </div>

                                        <div id='coachEvaluations' class="w-full grid grid-cols-10"></div>
                                    </div>
                                </body>
                            `
        var el = document.createElement( 'html' );
        el.innerHTML = `<html>${evaluationHTML}</html>`;
        let evaluationBody = el.querySelector('#coachEvaluations')
        var userIndex=1

        for (let i=0;i<coachEvaluations.length;i++) {
            var evaluationsList = coachEvaluations[i]['evaluationList']
            if (evaluationsList.length==0){
                continue
            }
            var strHTML = `
                        <div class="col-span-10 p-1 flex h-full items-start">
                            <div>
                                <span class="text-sm text-left">작성자 ${i+1}</span>
                            </div>
                        </div>
            `;
            for (let evaluation of evaluationsList){
                if (evaluation['ground_on']!='<p></p>' & evaluation['ground_off']!='<p></p>'){
                    let halfEvaluationHTML = `
                        <div class="col-span-10 border-t border-l border-r border-gray-500 flex items-center p-1 font-bold text-sm">${evaluation['half']} 그라운드(ON)</div>
                        <div class="col-span-10 border p-1 border-gray-500 flex items-center text-xs">
                            ${evaluation['ground_on']}
                        </div>
                        <div class="col-span-10 border-l border-r border-gray-500 flex items-center p-1 font-bold text-sm">${evaluation['half']} 그라운드(OFF)</div>
                        <div class="col-span-10 border p-1 border-gray-500 flex items-center text-xs">
                            ${evaluation['ground_off']}
                        </div>
                    `;
                    strHTML+=halfEvaluationHTML;
                }
            };
            evaluationBody.insertAdjacentHTML( 'beforeend', strHTML );
            userIndex+=1
        }

        var evaluationElement = el.getElementsByTagName('body')[0]
        html2pdf().from(evaluationElement).toPdf().save('evaluation.pdf')

    }
}
