import { Controller } from "stimulus"

export default class extends Controller {
    player_id = document.getElementById('playerId').value;

    button_sector_scouting = document.getElementById('button_sector_scouting');
    button_sector_record = document.getElementById('button_sector_record');
    sector_scouting = document.getElementById('sector_scouting');
    sector_record = document.getElementById('sector_record');

    button_tab_evaluation = document.getElementById('buttonTabEvaluation');
    button_tab_report = document.getElementById('buttonTabReport');
    button_tab_comment = document.getElementById('buttonTabComment');
    button_tab_video = document.getElementById('buttonTabVideo');
    button_tab_transaction = document.getElementById('buttonTabTransaction');

    tab_evaluation = document.getElementById('tab_evaluation');
    tab_report = document.getElementById('tab_report');
    tab_comment = document.getElementById('tab_comment');
    tab_video = document.getElementById('tab_video');
    tab_transaction = document.getElementById('tab_transaction');
    
    // record- tab buttons
    button_tab_standard = document.getElementById('buttonTabStandard');
    button_tab_split = document.getElementById('buttonTabSplit');
    button_tab_game_logs = document.getElementById('buttonTabGameLogs');
    button_tab_trackings = document.getElementById('buttonTabTrackings');

    tab_standard = document.getElementById('tabStandard');
    tab_split = document.getElementById('tabSplit');
    tab_game_logs = document.getElementById('tabGameLogs');
    tab_trackings = document.getElementById('tabTrackings');

    // record- split buttons
    button_split_tab_kbo = document.getElementById('buttonTabSplitKBO');
    button_split_tab_mlb = document.getElementById('buttonTabSplitMLB');
    button_split_tab_milb = document.getElementById('buttonTabSplitMiLB');
    button_split_tab_npb = document.getElementById('buttonTabSplitNPB');
    button_split_tab_cpbl = document.getElementById('buttonTabSplitCPBL');

    split_tab_kbo = document.getElementById('tabKBOSplit');
    split_tab_mlb = document.getElementById('tabMLBSplit');
    split_tab_milb = document.getElementById('tabMiLBSplit');
    split_tab_npb = document.getElementById('tabNPBSplit');
    split_tab_cpbl = document.getElementById('tabCPBLSplit');

    // record- game_logs buttons
    button_game_logs_tab_kbo = document.getElementById('buttonTabGameLogsKBO');
    button_game_logs_tab_mlb = document.getElementById('buttonTabGameLogsMLB');
    button_game_logs_tab_milb = document.getElementById('buttonTabGameLogsMiLB');
    button_game_logs_tab_npb = document.getElementById('buttonTabGameLogsNPB');
    button_game_logs_tab_cpbl = document.getElementById('buttonTabGameLogsCPBL');

    game_logs_tab_kbo = document.getElementById('tabKBOGameLogs');
    game_logs_tab_mlb = document.getElementById('tabMLBGameLogs');
    game_logs_tab_milb = document.getElementById('tabMiLBGameLogs');
    game_logs_tab_npb = document.getElementById('tabNPBGameLogs');
    game_logs_tab_cpbl = document.getElementById('tabCPBLGameLogs');

    kboGameLogTable = document.getElementById('kboGameLogTable')
    mlbGameLogTable = document.getElementById('mlbGameLogTable')
    milbGameLogTable = document.getElementById('milbGameLogTable')
    npbGameLogTable = document.getElementById('npbGameLogTable')
    cpblGameLogTable = document.getElementById('cpblGameLogTable')

    //record - tracking
    buttonTrackingTabKBO = document.getElementById('buttonTabTrackingsKBO');
    buttonTrackingTabMLB = document.getElementById('buttonTabTrackingsMLB');
    buttonTrackingTabMiLB = document.getElementById('buttonTabTrackingsMiLB');
    buttonTrackingTabNPB = document.getElementById('buttonTabTrackingsNPB');
    buttonTrackingTabCPBL = document.getElementById('buttonTabTrackingsCPBL');

    trackingsTabKBO = document.getElementById('tabKBOTrackings');
    trackingsTabMLB = document.getElementById('tabMLBTrackings');
    trackingsTabMiLB = document.getElementById('tabMiLBTrackings');
    trackingsTabNPB = document.getElementById('tabNPBTrackings');
    trackingsTabCPBL = document.getElementById('tabCPBLTrackings');

    kboTrackingTable = document.getElementById('kboTrackingTable')
    mlbTrackingTable = document.getElementById('mlbTrackingTable')
    milbTrackingTable = document.getElementById('milbTrackingTable')
    npbTrackingTable = document.getElementById('npbTrackingTable')
    cpblTrackingTable = document.getElementById('cpblTrackingTable')

    initialize(){
        if (this.kboGameLogTable!=null){
            $('#kboGameLogTable').tablesorter()
        }
        if (this.mlbGameLogTable!=null){
            $('#mlbGameLogTable').tablesorter()
        }
        if (this.milbGameLogTable!=null){
            $('#mlbGameLogTable').tablesorter()
        }
        if (this.npbGameLogTable!=null){
            $('#npbGameLogTable').tablesorter()
        }
        if (this.cpblGameLogTable!=null){
            $('#cpblGameLogTable').tablesorter()
        }

        // Tracking Table
        if (this.kboTrackingTable!=null){
            $('#kboTrackingTable').tablesorter({
                sortAppend: [ [0,1] ]
            })
        }
        if (this.mlbTrackingTable!=null){
            $('#mlbTrackingTable').tablesorter({
                sortAppend: [ [0,1] ]
            })
        }
        if (this.milbTrackingTable!=null){
            $('#milbTrackingTable').tablesorter({
                sortAppend: [ [0,1] ]
            })
        }
        if (this.npbTrackingTable!=null){
            $('#npbTrackingTable').tablesorter({
                sortAppend: [ [0,1] ]
            })
        }
        if (this.cpblTrackingTable!=null){
            $('#cpblTrackingTable').tablesorter({
                sortAppend: [ [0,1] ]
            })
        }


    }

    connect(){
        $('.button_int_video').on('click',function(event) {
            var myTarget = $(event.target).find('.int_video')
            if (myTarget.is(":visible")==true){
                myTarget.fadeOut()
                myTarget.hide() 
            }
            else{
                myTarget.fadeIn()
                myTarget.show()
            }
            var videoId = myTarget.attr('video-id')
            var videoName = myTarget.attr('name')
            let data = { 
                "player_id":this.player_id,
                "kind" : 'show',
                "sector": 'scouting',
                "tab": 'video',
                "tab_kind": 'show',
                "international_scout_video_id": videoId,
                "report_name":videoName
            }
            setLogs(data)
        })
        $(window).on('resize', function(){
            $('.international-pitcher-scout-comment').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
            $('.evaluation-comment').each(function(){
                $(this).css('height', 'auto' );
                $(this).height(this.scrollHeight)
            })
        });
    }

    // show record sector
    showScoutingSector(event){
        if ($('#button_sector_scouting').hasClass('bg-black text-white')==false){
            this.button_sector_scouting.classList.replace('bg-white', 'bg-black');
            this.button_sector_scouting.classList.replace('text-black', 'text-white');
        }
        this.button_sector_record.classList.replace('bg-black', 'bg-white');
        this.button_sector_record.classList.replace('text-white', 'text-black');
    
        this.sector_scouting.style.display = 'block';
        this.sector_record.style.display = 'none';

        $('.international-pitcher-scout-comment').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })
        $('.evaluation-comment').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
        }
        setLogs(data)
    }

    showEvaluationTab(event){
        if ($('#buttonTabEvaluation').hasClass('bg-black text-white')==false){
            this.button_tab_evaluation.classList.replace('bg-white', 'bg-black');
            this.button_tab_evaluation.classList.replace('text-gray-400', 'text-white');
        }

        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_evaluation.style.display = 'block';
        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'evaluation',
        }
        setLogs(data)
    }

    showEvaluation(event){
        const evaluationId = event.target.getAttribute("evaluationId")
  
        if ($(`#evaluation-${evaluationId}`).is(":visible")==true){
          $(`#evaluation-${evaluationId}`).fadeOut()
          $(`#evaluation-${evaluationId}`).hide() 
        }
        else{
          $(`#evaluation-${evaluationId}`).fadeIn()
          $(`#evaluation-${evaluationId}`).show()
        }

        $('.evaluation-comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
            "tab_kind": 'show',
            "international_scout_evaluation_id": evaluationId,
        }
        setLogs(data)
    }

    showScoutingReportTab(event){
        if ($('#buttonTabReport').hasClass('bg-black text-white')==false){
            this.button_tab_report.classList.replace('bg-white', 'bg-black');
            this.button_tab_report.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_evaluation.classList.replace('bg-black', 'bg-white');
        this.button_tab_evaluation.classList.replace('text-white', 'text-gray-400');

        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_evaluation.style.display = 'none';
        this.tab_report.style.display = 'block';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
        }
        setLogs(data)
    }

    showVideoTab(event){
        if ($('#buttonTabVideo').hasClass('bg-black text-white')==false){
            this.button_tab_video.classList.replace('bg-white', 'bg-black');
            this.button_tab_video.classList.replace('text-gray-400', 'text-white');
        }

        this.button_tab_evaluation.classList.replace('bg-black', 'bg-white');
        this.button_tab_evaluation.classList.replace('text-white', 'text-gray-400');

        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_evaluation.style.display = 'none';
        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'block';
        this.tab_transaction.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'video',
        }
        setLogs(data)
    }

    showScoutingCommentTab(event){
        if ($('#buttonTabComment').hasClass('bg-black text-white')==false){
            this.button_tab_comment.classList.replace('bg-white', 'bg-black');
            this.button_tab_comment.classList.replace('text-gray-400', 'text-white');
        }

        this.button_tab_evaluation.classList.replace('bg-black', 'bg-white');
        this.button_tab_evaluation.classList.replace('text-white', 'text-gray-400');

        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_evaluation.style.display = 'none';
        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'block';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'none';

        $('.international-pitcher-scout-comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'comment',
        }
        setLogs(data)
        
    }

    showTransactionTab(event){
        if ($('#buttonTabTransaction').hasClass('bg-black text-white')==false){
            this.button_tab_transaction.classList.replace('bg-white', 'bg-black');
            this.button_tab_transaction.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_evaluation.classList.replace('bg-black', 'bg-white');
        this.button_tab_evaluation.classList.replace('text-white', 'text-gray-400');

        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.tab_evaluation.style.display = 'none';
        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'block';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'transaction',
        }
        setLogs(data)
    }

    // show record sector
    showRecordSector(event){
        if ($('#button_sector_record').hasClass('bg-black text-white')==false){
            this.button_sector_record.classList.replace('bg-white', 'bg-black');
            this.button_sector_record.classList.replace('text-black', 'text-white');
        }
        this.button_sector_scouting.classList.replace('bg-black', 'bg-white');
        this.button_sector_scouting.classList.replace('text-white', 'text-black');

        this.sector_scouting.style.display = 'none';
        this.sector_record.style.display = 'block';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
        }
        setLogs(data)
    }
    
    showRecordStandardTab(event){
        if ($('#tabStandard').hasClass('bg-black text-white')==false){
            this.button_tab_standard.classList.replace('bg-white', 'bg-black');
            this.button_tab_standard.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_split.classList.replace('bg-black', 'bg-white');
        this.button_tab_split.classList.replace('text-white', 'text-gray-400');

        this.button_tab_game_logs.classList.replace('bg-black', 'bg-white');
        this.button_tab_game_logs.classList.replace('text-white', 'text-gray-400');

        this.button_tab_trackings.classList.replace('bg-black', 'bg-white');
        this.button_tab_trackings.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'block';
        this.tab_split.style.display = 'none';
        this.tab_game_logs.style.display = 'none';
        this.tab_trackings.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'standard',
        }
        setLogs(data)
    }

    showRecordSplitTab(event){
        if ($('#tabSplit').hasClass('bg-black text-white')==false){
            this.button_tab_split.classList.replace('bg-white', 'bg-black');
            this.button_tab_split.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_standard.classList.replace('bg-black', 'bg-white');
        this.button_tab_standard.classList.replace('text-white', 'text-gray-400');

        this.button_tab_game_logs.classList.replace('bg-black', 'bg-white');
        this.button_tab_game_logs.classList.replace('text-white', 'text-gray-400');

        this.button_tab_trackings.classList.replace('bg-black', 'bg-white');
        this.button_tab_trackings.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'none';
        this.tab_split.style.display = 'block';
        this.tab_game_logs.style.display = 'none';
        this.tab_trackings.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'split',
        }
        setLogs(data)
    }

    showRecordGameLogsTab(event){
        if ($('#tabGameLogs').hasClass('bg-black text-white')==false){
            this.button_tab_game_logs.classList.replace('bg-white', 'bg-black');
            this.button_tab_game_logs.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_standard.classList.replace('bg-black', 'bg-white');
        this.button_tab_standard.classList.replace('text-white', 'text-gray-400');

        this.button_tab_split.classList.replace('bg-black', 'bg-white');
        this.button_tab_split.classList.replace('text-white', 'text-gray-400');

        this.button_tab_trackings.classList.replace('bg-black', 'bg-white');
        this.button_tab_trackings.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'none';
        this.tab_split.style.display = 'none';
        this.tab_game_logs.style.display = 'block';
        this.tab_trackings.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'game_logs',
        }
        setLogs(data)
    }

    showRecordTrackingsTab(event){
        if ($('#tabTrackings').hasClass('bg-black text-white')==false){
            this.button_tab_trackings.classList.replace('bg-white', 'bg-black');
            this.button_tab_trackings.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_standard.classList.replace('bg-black', 'bg-white');
        this.button_tab_standard.classList.replace('text-white', 'text-gray-400');

        this.button_tab_split.classList.replace('bg-black', 'bg-white');
        this.button_tab_split.classList.replace('text-white', 'text-gray-400');

        this.button_tab_game_logs.classList.replace('bg-black', 'bg-white');
        this.button_tab_game_logs.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'none';
        this.tab_split.style.display = 'none';
        this.tab_game_logs.style.display = 'none';
        this.tab_trackings.style.display = 'block';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'trackings',
        }
        setLogs(data)
    }

    showYears(event){
        const showYearId = event.target.id;
        let season = document.getElementById(showYearId).getAttribute("season")
        let changeText = '';
        $(`.${showYearId}_split`).each(function(){
            if(this.style.display==''){
                this.style.display = 'none';
                changeText = `${season} ▼`;
                event.target.innerHTML=changeText
            } else{
                this.style.display = '';
                changeText = `${season} ▲`;
                event.target.innerHTML=changeText
            }
        })
    }

    // show Split each leagues
    showRecordSplitKBO(event){
        if ($('#buttonTabSplitKBO').hasClass('bg-black text-white')==false){
            this.button_split_tab_kbo.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_kbo.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }
        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'block';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_milb!=null) this.split_tab_milb.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'kbo',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitMLB(event){
        if ($('#buttonTabSplitMLB').hasClass('bg-black text-white')==false){
            this.button_split_tab_mlb.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_mlb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'block';
        if(this.split_tab_milb!=null) this.split_tab_milb.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'mlb',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitMiLB(event){
        if ($('#buttonTabSplitMiLB').hasClass('bg-black text-white')==false){
            this.button_split_tab_milb.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_milb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_milb!=null) this.split_tab_milb.style.display = 'block';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'milb',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitNPB(event){
        if ($('#buttonTabSplitNPB').hasClass('bg-black text-white')==false){
            this.button_split_tab_npb.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_npb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_milb!=null) this.split_tab_milb.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'block';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'npb',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitCPBL(event){
        if ($('#buttonTabSplitCPBL').hasClass('bg-black text-white')==false){
            this.button_split_tab_cpbl.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_cpbl.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_milb!=null) this.split_tab_milb.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'block';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'cpbl',
            'type':'split'
        }
        setLogs(data)
    }

    changeSplitYear(event){
        
        let selectYearId =  event.target.id
        let selectYear =  event.target.value

        if (selectYearId.includes('Split') & selectYear!=''){
            if (selectYearId.includes('KBO')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 1, "level_id":1, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableKBOSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableKBOSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['pitcher_stand_split_records']
                        let monthSplitRecords = data['pitcher_month_split_records']

                        // pitcher Stand Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, pitcher_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bf']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['avg']==null ? '--' : pitcher_stand_split_record['avg']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ubb_rate']==null ? '--' : (pitcher_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k_rate']==null ? '--' : (pitcher_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // pitcher Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, pitcher_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['g']}(${pitcher_month_split_record['gs']})</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['win']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['lose']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hold']==null ? '--' : pitcher_month_split_record['hold']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['sv']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['ip']==null ? '--' : pitcher_month_split_record['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['era']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['r']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['er']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['whip']==null ? '0.00' : pitcher_month_split_record['whip']}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                    },
                });
            }
            else if (selectYearId.includes('MLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id":2, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableMLBSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableMLBSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['pitcher_stand_split_records']
                        let monthSplitRecords = data['pitcher_month_split_records']

                        // pitcher Stand Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, pitcher_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bf']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['avg']==null ? '--' : pitcher_stand_split_record['avg']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ubb_rate']==null ? '--' : (pitcher_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k_rate']==null ? '--' : (pitcher_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // pitcher Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, pitcher_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['g']}(${pitcher_month_split_record['gs']})</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['win']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['lose']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hold']==null ? '--' : pitcher_month_split_record['hold']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['sv']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['ip']==null ? '--' : pitcher_month_split_record['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['era']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['r']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['er']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['whip']==null ? '0.00' : pitcher_month_split_record['whip']}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                    },
                });
            }
            else if (selectYearId.includes('MiLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id":12, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableMiLBSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableMiLBSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['pitcher_stand_split_records']
                        let monthSplitRecords = data['pitcher_month_split_records']

                        // pitcher Stand Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, pitcher_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bf']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['avg']==null ? '--' : pitcher_stand_split_record['avg']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ubb_rate']==null ? '--' : (pitcher_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k_rate']==null ? '--' : (pitcher_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // pitcher Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, pitcher_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['g']}(${pitcher_month_split_record['gs']})</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['win']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['lose']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hold']==null ? '--' : pitcher_month_split_record['hold']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['sv']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['ip']==null ? '--' : pitcher_month_split_record['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['era']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['r']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['er']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['whip']==null ? '0.00' : pitcher_month_split_record['whip']}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }                        
                    },
                });
            }
            else if (selectYearId.includes('NPB')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 3, "level_id":1, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableNPBSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableNPBSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['pitcher_stand_split_records']
                        let monthSplitRecords = data['pitcher_month_split_records']

                        // pitcher Stand Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, pitcher_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bf']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['avg']==null ? '--' : pitcher_stand_split_record['avg']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ubb_rate']==null ? '--' : (pitcher_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k_rate']==null ? '--' : (pitcher_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // pitcher Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, pitcher_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['g']}(${pitcher_month_split_record['gs']})</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['win']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['lose']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hold']==null ? '--' : pitcher_month_split_record['hold']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['sv']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['ip']==null ? '--' : pitcher_month_split_record['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['era']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['r']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['er']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['whip']==null ? '0.00' : pitcher_month_split_record['whip']}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('CPBL')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 4, "level_id":1, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableCPBLSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableCPBLSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['pitcher_stand_split_records']
                        let monthSplitRecords = data['pitcher_month_split_records']

                        // pitcher Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, pitcher_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bf']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['avg']==null ? '--' : pitcher_stand_split_record['avg']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['ubb_rate']==null ? '--' :  (pitcher_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_stand_split_record['k_rate']==null ? '--' : (pitcher_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                        
                        // pitcher Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, pitcher_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${pitcher_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['gs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['win']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['lose']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hold']==null ? '--' : pitcher_month_split_record['hold']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['sv']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['ip']==null ? '--' : pitcher_month_split_record['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['era']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['r']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['er']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${pitcher_month_split_record['whip']==null ? '0.00' : pitcher_month_split_record['whip']}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
        }

    }


    // show Game Logs each leagues
    showRecordGameLogsKBO(event){
        if ($('#buttonTabGameLogsKBO').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_kbo.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_kbo.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }
        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'block';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'kbo',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsMLB(event){
        if ($('#buttonTabGameLogsMLB').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_mlb.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_mlb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'block';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'mlb',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsMiLB(event){
        if ($('#buttonTabGameLogsMiLB').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_milb.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_milb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'block';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'milb',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsNPB(event){
        if ($('#buttonTabGameLogsNPB').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_npb.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_npb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'block';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'npb',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsCPBL(event){
        if ($('#buttonTabGameLogsCPBL').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_cpbl.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_cpbl.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'block';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'cpbl',
            'type':'game_logs'
        }
        setLogs(data)
    }

    changeGameLogYear(event){
        
        let selectYearId =  event.target.id
        let selectYear =  event.target.value

        if (selectYearId.includes('GameLog') & selectYear!=''){
            if (selectYearId.includes('KBO')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 1, "level_id":1, "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableKBOGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['pitcher_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['result']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['np']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['earned_runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 2, "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableMLBGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['pitcher_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['result']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['np']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['earned_runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MiLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": '6,7,8,9', "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableMiLBGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['pitcher_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['result']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['np']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['earned_runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('NPB')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 3, "level_id":1, "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableNPBGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['pitcher_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['result']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['np']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['earned_runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('CPBL')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 4, "level_id":"1", "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableCPBLGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['pitcher_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['result']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['np']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ip']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['earned_runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
        }

    }

    // show Pitch Tracking each leagues
    showRecordTrackingsKBO(event){
        if ($('#buttonTrackingsKBO').hasClass('bg-black text-white')==false){
            this.buttonTrackingTabKBO.classList.replace('bg-white', 'bg-black');
            this.buttonTrackingTabKBO.classList.replace('text-gray-400', 'text-white');
        }
        if(this.buttonTrackingTabMLB!=null){
            this.buttonTrackingTabMLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMiLB!=null){
            this.buttonTrackingTabMiLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMiLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabNPB!=null){
            this.buttonTrackingTabNPB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabNPB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabCPBL!=null){
            this.buttonTrackingTabCPBL.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabCPBL.classList.replace('text-white', 'text-gray-400');
        }
        if(this.trackingsTabKBO!=null) this.trackingsTabKBO.style.display = 'block';
        if(this.trackingsTabMLB!=null) this.trackingsTabMLB.style.display = 'none';
        if(this.trackingsTabMiLB!=null) this.trackingsTabMiLB.style.display = 'none';
        if(this.trackingsTabNPB!=null) this.trackingsTabNPB.style.display = 'none';
        if(this.trackingsTabCPBL!=null) this.trackingsTabCPBL.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'kbo',
            'type':'trackings'
        }
        setLogs(data)
    }

    showRecordTrackingsMLB(event){
        if ($('#buttonTabTrackingsMLB').hasClass('bg-black text-white')==false){
            this.buttonTrackingTabMLB.classList.replace('bg-white', 'bg-black');
            this.buttonTrackingTabMLB.classList.replace('text-gray-400', 'text-white');
        }
        if(this.buttonTrackingTabKBO!=null){
            this.buttonTrackingTabKBO.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabKBO.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMiLB!=null){
            this.buttonTrackingTabMiLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMiLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabNPB!=null){
            this.buttonTrackingTabNPB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabNPB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabCPBL!=null){
            this.buttonTrackingTabCPBL.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabCPBL.classList.replace('text-white', 'text-gray-400');
        }

        if(this.trackingsTabKBO!=null) this.trackingsTabKBO.style.display = 'none';
        if(this.trackingsTabMLB!=null) this.trackingsTabMLB.style.display = 'block';
        if(this.trackingsTabMiLB!=null) this.trackingsTabMiLB.style.display = 'none';
        if(this.trackingsTabNPB!=null) this.trackingsTabNPB.style.display = 'none';
        if(this.trackingsTabCPBL!=null) this.trackingsTabCPBL.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'mlb',
            'type':'trackings'
        }
        setLogs(data)
    }

    showRecordTrackingsMiLB(event){
        if ($('#buttonTabTrackingsMiLB').hasClass('bg-black text-white')==false){
            this.buttonTrackingTabMiLB.classList.replace('bg-white', 'bg-black');
            this.buttonTrackingTabMiLB.classList.replace('text-gray-400', 'text-white');
        }
        if(this.buttonTrackingTabKBO!=null){
            this.buttonTrackingTabKBO.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabKBO.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMLB!=null){
            this.buttonTrackingTabMLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabNPB!=null){
            this.buttonTrackingTabNPB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabNPB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabCPBL!=null){
            this.buttonTrackingTabCPBL.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabCPBL.classList.replace('text-white', 'text-gray-400');
        }

        if(this.trackingsTabKBO!=null) this.trackingsTabKBO.style.display = 'none';
        if(this.trackingsTabMLB!=null) this.trackingsTabMLB.style.display = 'none';
        if(this.trackingsTabMiLB!=null) this.trackingsTabMiLB.style.display = 'block';
        if(this.trackingsTabNPB!=null) this.trackingsTabNPB.style.display = 'none';
        if(this.trackingsTabCPBL!=null) this.trackingsTabCPBL.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'milb',
            'type':'trackings'
        }
        setLogs(data)
    }

    showRecordTrackingsNPB(event){
        if ($('#buttonTabTrackingsNPB').hasClass('bg-black text-white')==false){
            this.buttonTrackingTabNPB.classList.replace('bg-white', 'bg-black');
            this.buttonTrackingTabNPB.classList.replace('text-gray-400', 'text-white');
        }
        if(this.buttonTrackingTabKBO!=null){
            this.buttonTrackingTabKBO.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabKBO.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMLB!=null){
            this.buttonTrackingTabMLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMiLB!=null){
            this.buttonTrackingTabMiLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMiLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabCPBL!=null){
            this.buttonTrackingTabCPBL.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabCPBL.classList.replace('text-white', 'text-gray-400');
        }

        if(this.trackingsTabKBO!=null) this.trackingsTabKBO.style.display = 'none';
        if(this.trackingsTabMLB!=null) this.trackingsTabMLB.style.display = 'none';
        if(this.trackingsTabMiLB!=null) this.trackingsTabMiLB.style.display = 'none';
        if(this.trackingsTabNPB!=null) this.trackingsTabNPB.style.display = 'block';
        if(this.trackingsTabCPBL!=null) this.trackingsTabCPBL.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'npb',
            'type':'trackings'
        }
        setLogs(data)
    }

    showRecordTrackingsCPBL(event){
        if ($('#buttonTabTrackingsCPBL').hasClass('bg-black text-white')==false){
            this.buttonTrackingTabCPBL.classList.replace('bg-white', 'bg-black');
            this.buttonTrackingTabCPBL.classList.replace('text-gray-400', 'text-white');
        }
        if(this.buttonTrackingTabKBO!=null){
            this.buttonTrackingTabKBO.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabKBO.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMLB!=null){
            this.buttonTrackingTabMLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabMiLB!=null){
            this.buttonTrackingTabMiLB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabMiLB.classList.replace('text-white', 'text-gray-400');
        }
        if(this.buttonTrackingTabNPB!=null){
            this.buttonTrackingTabNPB.classList.replace('bg-black', 'bg-white');
            this.buttonTrackingTabNPB.classList.replace('text-white', 'text-gray-400');
        }

        if(this.trackingsTabKBO!=null) this.trackingsTabKBO.style.display = 'none';
        if(this.trackingsTabMLB!=null) this.trackingsTabMLB.style.display = 'none';
        if(this.trackingsTabMiLB!=null) this.trackingsTabMiLB.style.display = 'none';
        if(this.trackingsTabNPB!=null) this.trackingsTabNPB.style.display = 'none';
        if(this.trackingsTabCPBL!=null) this.trackingsTabCPBL.style.display = 'block';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'cpbl',
            'type':'trackings'
        }
        setLogs(data)
    }

    changeTrackingYear(event){
        
        let selectYearId =  event.target.id
        let selectYear =  event.target.value

        if (selectYearId.includes('Trackings') & selectYear!=''){
            if (selectYearId.includes('KBO')){
                $.ajax({
                    url: '/scouts/international_scouts/getTrackings',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 1, "level_id":1, "season":selectYear},
                    success: function (data){
                        let trackingTableTbodyElement = document.getElementById('tableKBOTrackingTbody')
                        trackingTableTbodyElement.innerHTML=''
                        let trackingRecords = data['trackings']

                        // pitcher Split Table
                        if (trackingRecords.length>0){
                            for(let [ index, tracking ] of trackingRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${tracking['pitch_type']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['usage']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['avg_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['max_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['spin_rate']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['induced_vert_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['horz_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_height']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_side']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['extension']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['whiff_percentage']}</td>
                                    </tr>
                                `
                                trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="11" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exist.</td></tr>'
                            trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getTrackings',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 2, "season":selectYear},
                    success: function (data){
                        let trackingTableTbodyElement = document.getElementById('tableMLBTrackingTbody')
                        trackingTableTbodyElement.innerHTML=''
                        let trackingRecords = data['trackings']

                        // pitcher Split Table
                        if (trackingRecords.length>0){
                            for(let [ index, tracking ] of trackingRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${tracking['pitch_type']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['usage']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['avg_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['max_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['spin_rate']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['induced_vert_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['horz_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_height']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_side']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['extension']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['whiff_percentage']}</td>
                                    </tr>
                                `
                                trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="11" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MiLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getTrackings',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": '6,7,8,9', "season":selectYear},
                    success: function (data){
                        let trackingTableTbodyElement = document.getElementById('tableMiLBTrackingTbody')
                        trackingTableTbodyElement.innerHTML=''
                        let trackingRecords = data['trackings']

                        // pitcher Split Table
                        if (trackingRecords.length>0){
                            for(let [ index, tracking ] of trackingRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${tracking['pitch_type']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['usage']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['avg_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['max_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['spin_rate']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['induced_vert_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['horz_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_height']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_side']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['extension']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['whiff_percentage']}</td>
                                    </tr>
                                `
                                trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="11" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exist.</td></tr>'
                            trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('NPB')){
                $.ajax({
                    url: '/scouts/international_scouts/getTrackings',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 3, "level_id":1, "season":selectYear},
                    success: function (data){
                        let trackingTableTbodyElement = document.getElementById('tableNPBTrackingTbody')
                        trackingTableTbodyElement.innerHTML=''
                        let trackingRecords = data['trackings']

                        // pitcher Split Table
                        if (trackingRecords.length>0){
                            for(let [ index, tracking ] of trackingRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${tracking['pitch_type']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['usage']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['avg_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['max_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['spin_rate']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['induced_vert_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['horz_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_height']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_side']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['extension']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['whiff_percentage']}</td>
                                    </tr>
                                `
                                trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="11" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exist.</td></tr>'
                            trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('CPBL')){
                $.ajax({
                    url: '/scouts/international_scouts/getTrackings',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 4, "level_id":"1", "season":selectYear},
                    success: function (data){
                        let trackingTableTbodyElement = document.getElementById('tableCPBLTrackingTbody')
                        trackingTableTbodyElement.innerHTML=''
                        let trackingRecords = data['trackings']

                        // pitcher Split Table
                        if (trackingRecords.length>0){
                            for(let [ index, tracking ] of trackingRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${tracking['pitch_type']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['usage']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['avg_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['max_rel_speed']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['spin_rate']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['induced_vert_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['horz_break']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_height']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['rel_side']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['extension']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${tracking['whiff_percentage']}</td>
                                    </tr>
                                `
                                trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="11" class="border whitespace-no-wrap text-center px-6 py-2">Record does not exists.</td></tr>'
                            trackingTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
        }

    }

    newWindowReport(event){
        const reportSrc = document.getElementById('divOpenReport').getAttribute("src")
        const reportId = document.getElementById('divOpenReport').getAttribute("report-id")
        
        $.ajax({
            url: '/scouts/international_scout_trackman_reports/open_reports',
            method: 'GET',
            async: false,
            data: { "international_scout_trackman_report_id": reportId},
            success: function (data){
            window.open(reportSrc, '_blank'); 
            },
        });
    }
    
    showReport(event){
        const season = event.target.id
        const reportId = event.target.getAttribute("report-id")
  
        if ($(`#report_${season}`).is(":visible")==true){
          $(`#report_${season}`).fadeOut()
          $(`#report_${season}`).hide() 
        }
        else{
          $(`#report_${season}`).fadeIn()
          $(`#report_${season}`).show()
        }

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
            "tab_kind": 'show',
            "international_scout_trackman_report_id": reportId,
        }
        setLogs(data)
    }
    
    openReport(event){
        const reportSrc = event.target.getAttribute("src")
        const reportId = event.target.getAttribute("report-id")

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
            "tab_kind": 'open',
            "international_scout_trackman_report_id": reportId,
        }
        setLogs(data)
        window.open(reportSrc, '_blank');
    }

}

function setLogs(data){
    $.ajax({
        url: '/scouts/international_scouts/set_logs',
        method: 'GET',
        async: false,
        data: data
    });        
}
