// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]
  initialize(){
      $(document).ready(function() {
        $('.coach-select').select2({
          placeholder: "코치 선택",
          width: '100%',
        });
      })
      $('.comment').each(function(){
          //scroll height가 client height의 길이를 뛰어넘는 지점에서
          // if (elem.clientHeight < elem.scrollHeight)
          if(this.clientHeight < this.scrollHeight){
            $(this).height(this.scrollHeight)
          }
      });
  }

  connect() {
    $('#trainer_evaluation_form').on('submit',function(event){
      var isReturn=true

      const year = document.getElementById('trainer-evaluation-season').value
      const userId = document.getElementById('trainer-evaluation-user-id').value
      
      // check year and month
      if (['', null, undefined].includes(year)){
        if (['', null, undefined].includes(year)){
          alert('평가 년도를 입력해주세요')
        }
        isReturn=false
        return false
      }

      // check coach_id
      $('.each-trainer-evaluation').each(function(index){
        var current_coach = $('.coach-select').eq(index).val()
        if (current_coach==""){
          alert(`${index+1}번 평가서의 코치를 선택해주세요.`)
          event.preventDefault()
          isReturn=false
          return false
        }
      });

      // check year and user_id
      $.ajax({
        url: '/evaluations/trainer_evaluations/check_duplicate',
        method: 'POST',
        async: false,
        data: { "season": year, "user_id":userId},
        success: function (data){
          if(data['duplicate']>=1){
            alert(`이미 작성된 평가서가 있습니다.`)
            event.preventDefault()
            isReturn=false
            return false
          }
        },
      });

      //submit or break
      if (isReturn==false){
        return false
      }else{
        alert('작성 완료')
        return true
      }
    })
    $(window).on('resize', function(){
      $('.comment').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
    });
  }

  add_coach(event) {
      const content = this.templateTarget.innerHTML.replace(/NEW_COACH/g, new Date().getTime())
      this.linksTarget.insertAdjacentHTML("beforebegin", content)
      $('.coach-select').select2({
          placeholder: "코치 선택",
          width: '100%',
          height: '100%'
      });
      const year = document.getElementById('trainer-evaluation-season').value

      $('.each_training_coach_evaluation_season').val(year)
  }
  
  remove_coach(event) {
      const wrapper = event.target.closest(".each-trainer-evaluation")
      if (wrapper.dataset.newRecord == "true") {
          wrapper.remove()
      }
      else {
          wrapper.querySelector("input[name*='_destroy']").value = 1
          wrapper.style.display = "none"
      }
  }

  setYear(event){
    const yearSelect = event.target.value
    $('.each_training_coach_evaluation_season').each(function(index){
      $('.each_training_coach_evaluation_season').eq(index).val(yearSelect)
    });
  }

  add_onground_point(event){
      const wrapper = event.target.closest(".each-trainer-evaluation")
      let onground_knowledge = wrapper.querySelector(("input[name*='onground_knowledge']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_knowledge']:checked")).value) : 0
      let onground_coaching = wrapper.querySelector(("input[name*='onground_coaching']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_coaching']:checked")).value) : 0
      let onground_attitude = wrapper.querySelector(("input[name*='onground_attitude']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_attitude']:checked")).value) : 0
      let onground_communication = wrapper.querySelector(("input[name*='onground_communication']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_communication']:checked")).value) : 0
      let onground_responsibility = wrapper.querySelector(("input[name*='onground_responsibility']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_responsibility']:checked")).value) : 0

      let evaluation = onground_knowledge+onground_coaching+onground_attitude+onground_communication+onground_responsibility
      
      wrapper.querySelector(".training-evalution-title").innerHTML = `평가내용 - ${evaluation}점`
  }

  setHeight(event){
      const wrapper = event.target
      wrapper.style.height = 'auto'
      wrapper.style.height=wrapper.scrollHeight+"px"
  }

  createPDF(){
    var year = $('#season').val()
    fetch(`/evaluations/get_season_evaluations?season=${year}&coachId=${this.coachId}`)
    .then(resp => resp.json())
    .then(data => {
        this.evaluation = data['trainer_season_evaluations'];
        this.setEvaluationPDFContents();
    });
  }

  setEvaluationPDFContents(){
      var trainerInformation = this.evaluation['trainer_information']
      var trainerScore = this.evaluation['trainer_evaluation_score']
      let trainerEvaluations = this.evaluation['user_coach_evaluations']
      var evaluationHTML = `
                              <head>
                                  <script src="https://cdn.tailwindcss.com"></script>
                              </head>
                              <body>
                                  <div class="h-[210mm] w-[290mm] px-12" id="evaluation">
                                      <div class='inline-flex items-center justify-center w-full'>
                                          <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded">
                                          <h2 class="w-auto px-2 absolute bg-white text-2xl">
                                              ${trainerInformation['season']}시즌 트레이너 평가서
                                          </h2>
                                      </div>

                                      <div>
                                          <h3 class="text-xl font-bold my-1 batting-evaluation">1.트레이너 정보</h3>
                                      </div>

                                      <div class='grid grid-cols-3'>
                                          <div class='col-span-1 text-sm text-center border-r border-t border-l font-bold p-1'>이름</div>
                                          <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>편성</div>
                                          <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>보직</div>
                                          <div class='col-span-1 text-center border-r border-t border-b border-l text-xs'>
                                              ${trainerInformation['name_kor']}
                                          </div>
                                          <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                              ${trainerInformation['department_name']}
                                          </div>
                                          <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                              ${trainerInformation['role_name']}
                                          </div>
                                      </div>

                                      <div class='h-2'></div>

                                      <div>
                                          <h3 class="text-xl font-bold my-1 batting-evaluation">2. 평가점수</h3>
                                      </div>

                                      <div class='grid grid-cols-5'>
                                          <div class='col-span-1 text-sm text-center border-r border-t border-l font-bold p-1'>전문성</div>
                                          <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>코칭태도</div>
                                          <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>소통</div>
                                          <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>책임감</div>
                                          <div class='col-span-1 text-sm text-center border-r border-t font-bold p-1'>총점</div>
                                          <div class='col-span-1 text-center border-r border-t border-b border-l text-xs'>
                                              ${trainerScore['knowledge']}
                                          </div>
                                          <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                              ${trainerScore['coaching']}
                                          </div>
                                          <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                              ${trainerScore['communication']}
                                          </div>
                                          <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                              ${trainerScore['responsibility']}
                                          </div>
                                          <div class='col-span-1 text-center border-r border-t border-b text-xs'>
                                              ${trainerScore['total']}
                                          </div>
                                      </div>

                                      <div class='h-2'></div>

                                      <div>
                                          <h3 class="text-xl font-bold my-1 batting-evaluation">3. 평가내용</h3>
                                      </div>

                                      <div id='trainerEvaluations' class="w-full grid grid-cols-10"></div>
                                  </div>
                              </body>
                          `
      var el = document.createElement( 'html' );
      el.innerHTML = `<html>${evaluationHTML}</html>`;
      let evaluationBody = el.querySelector('#trainerEvaluations')
      var userIndex=1

      for (let i=0;i<trainerEvaluations.length;i++) {
          var evaluationsList = trainerEvaluations[i]['evaluationList']
          if (evaluationsList.length==0){
              continue
          }
          var strHTML = `
                      <div class="col-span-10 p-1 flex h-full items-start">
                          <div>
                              <span class="text-sm text-left">작성자 ${i+1}</span>
                          </div>
                      </div>
          `;
          for (let evaluation of evaluationsList){
              if (evaluation['ground_on']!='<p></p>' & evaluation['ground_off']!='<p></p>'){
                  let halfEvaluationHTML = `
                      <div class="col-span-10 border-t border-l border-r flex items-center p-1 font-bold text-sm">${evaluation['half']} 코멘트 </div>
                      <div class="col-span-10 border p-1 flex items-center text-xs">
                          ${evaluation['ground_on']}
                      </div>
                  `;
                  strHTML+=halfEvaluationHTML;
              }
          };
          evaluationBody.insertAdjacentHTML( 'beforeend', strHTML );
          userIndex+=1
      }

      var evaluationElement = el.getElementsByTagName('body')[0]
      html2pdf().from(evaluationElement).toPdf().save('evaluation.pdf')

  }

}